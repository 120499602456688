import { useState } from "react";
import { HashLink } from "react-router-hash-link";
// ----IMAGES---- //
// Sound Modul
import SoundModul1 from "../../../Images/usluge/Sound-modul2.JPG";
import SoundModul2 from "../../../Images/usluge/Panthera.JPG";
import SoundModul3 from "../../../Images/usluge/Sound-modul1.jpg";

// Tapeciranje
import Tapeciranje1 from "../../../Images/usluge/Tapeciranje.jpeg";
import Tapeciranje2 from "../../../Images/usluge/Tapeciranje-volana.jpg";
import Tapeciranje3 from "../../../Images/usluge/Tapeciranje3.jpeg";
import Tapeciranje4 from "../../../Images/usluge/Tapeciranje2.jpeg";
// Wrap
import Wrap from "../../../Images/usluge/Wrap-GT.jpg";
import WrapSCoupe from "../../../Images/usluge/Wrap-S-Coupe.jpg";
import WrapSmart from "../../../Images/usluge/Wrap-Smart.jpg";
// Ugradnja felgi
import UgradnjaFelgi1 from "../../../Images/usluge/Felge.jpg";
import UgradnjaFelgi2 from "../../../Images/usluge/AutoConnect-Felge.jpg";
import UgradnjaFelgi3 from "../../../Images/usluge/Felge2.jpg";
// Pojasevi u boji
import Pojasevi1 from "../../../Images/usluge/Pojasevi-crveni.jpg";
import Pojasevi2 from "../../../Images/usluge/Pojasevi-plavi.jpg";
import Pojasevi3 from "../../../Images/usluge/Pojasevi.jpg";
// Bojanje čeljusti
import BojanjeČeljusti1 from "../../../Images/usluge/Čeljusti2.jpg";
import BojanjeČeljusti2 from "../../../Images/usluge/Čeljusti.jpeg";
import BojanjeČeljusti3 from "../../../Images/usluge/Bojanje-čeljusti.jpg";
// Zatamnjivanje stakla
import ZatamnjivanjeStakla1 from "../../../Images/usluge/Stakla.jpg";
import ZatamnjivanjeStakla2 from "../../../Images/usluge/Stakla2.jpg";
import ZatamnjivanjeStakla3 from "../../../Images/usluge/Stakla3.JPG";
// Language
import { useTranslation } from "react-i18next";

function UslugeBox({
  imageSrc,
  altText,
  title,
  description,
  description2,
  listItems,
  imageSrc1,
  imageSrc2,
  imageSrc3,
  alt1,
  alt2,
  alt3,
  link,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const toggleUslugeBox = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* **********USLUGE********** */}
      <div className="services-box">
        <img src={imageSrc} alt={altText} className="services-img" />
        <div className="services-title" onClick={toggleUslugeBox}>
          <h5>
            {title}
            <i className="fi fi-ss-angle-small-down open-usluge-box"></i>
          </h5>
        </div>
        {/* <!-- OPEN USLUGE BOX ON CLICK --> */}
        <div className={`usluge-box ${isOpen ? "open" : ""}`}>
          <p>{description}</p>
          <p>{description2}</p>
          <ul>
            {listItems.map((item, index) => (
              <li key={index}>&#x2022; {item}</li>
            ))}
          </ul>
          <div className="usluge-img">
            <img
              src={imageSrc1}
              alt={alt1}
              onClick={() => openImage(imageSrc1)}
            />
            <img
              src={imageSrc2}
              alt={alt2}
              onClick={() => openImage(imageSrc2)}
            />
            <img
              src={imageSrc3}
              alt={alt3}
              onClick={() => openImage(imageSrc3)}
            />
          </div>
          <div className="usluge-btn">{link}</div>
        </div>
        {/*  <!-- ---- --> */}
      </div>

      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default function NaseUsluge() {
  const handleClick = (path) => {
    window.scrollTo(0, 0);
  };
  const { t } = useTranslation();
  return (
    <>
      <section className="section section-usluge">
        <h1>{t("usluge.uslugeTitle")}</h1>
        <p>{t("usluge.uslugeBio1")}</p>
        <p>{t("usluge.uslugeBio2")}</p>
        <p>{t("usluge.uslugeBio3")}</p>
        <div className="usluge-grid">
          {/* SOUND MODUL */}
          <UslugeBox
            imageSrc={SoundModul1}
            altText="Sound modul"
            title={t("soundModul.title")}
            description={t("soundModul.description1")}
            description2={t("soundModul.description2")}
            listItems={["Zvuk", "Ugradnja", "Bass"]}
            imageSrc1={SoundModul1}
            alt1="Sound modul"
            imageSrc2={SoundModul2}
            alt2="Sound modul"
            imageSrc3={SoundModul3}
            alt3="Sound modul"
            link={
              <HashLink
                smooth
                to="/sound-modul"
                onClick={() => handleClick("/sound-modul")}
              >
                {t("soundModul.moreBtn")} &rarr;
              </HashLink>
            }
          />
          {/* TAPECIRANJE */}
          <UslugeBox
            imageSrc={Tapeciranje1}
            altText="Tapeciranje"
            title={t("tapeciranje.title")}
            description={t("tapeciranje.description1")}
            description2={t("tapeciranje.description2")}
            listItems={[
              "Djelomično ili kompletno tapeciranje sjedala",
              "Obnova konstrukcije sjedala",
              "Presvlačenje volana u kožu ili imitaciju kože",
              "Tapeciranje naslona za ruku",
              "Obnova konstrukcije sjedala",
            ]}
            imageSrc1={Tapeciranje2}
            alt1="Tapeciranje"
            imageSrc2={Tapeciranje3}
            alt2="Tapeciranje"
            imageSrc3={Tapeciranje4}
            alt3="Tapeciranje"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                {t("tapeciranje.btn")}
              </HashLink>
            }
          />
          {/* WRAP */}
          <UslugeBox
            imageSrc={WrapSCoupe}
            altText="Wrap"
            title={t("wrap.title")}
            description={t("wrap.description1")}
            description2={t("wrap.description2")}
            listItems={[
              "Promjena izgleda",
              "Zaštita površine vozila od UV zraka i štetnih utjecaja",
              "Obnova boje",
              "Širok izbor boja i efekata",
            ]}
            imageSrc1={Wrap}
            alt1="Wrap"
            imageSrc2={WrapSCoupe}
            alt2="Wrap"
            imageSrc3={WrapSmart}
            alt3="Wrap"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                {t("wrap.btn")}
              </HashLink>
            }
          />
          {/* UGRADNJA FELGI */}
          <UslugeBox
            imageSrc={UgradnjaFelgi1}
            altText="Ugradnja felgi"
            title={t("felge.title")}
            description={t("felge.description1")}
            description2={t("felge.description2")}
            listItems={[
              "Razni brendovi u ponudi",
              "Montaža i zaštita felgi",
              "Ugradnja ventila",
            ]}
            imageSrc1={UgradnjaFelgi1}
            alt1="Ugradnja felgi"
            imageSrc2={UgradnjaFelgi2}
            alt2="Ugradnja felgi"
            imageSrc3={UgradnjaFelgi3}
            alt3="Ugradnja felgi"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                {t("felge.btn")}
              </HashLink>
            }
          />
          {/* POJASEVI U BOJI */}
          <UslugeBox
            imageSrc={Pojasevi1}
            altText="Pojasevi u boji"
            title={t("pojasevi.title")}
            description={t("pojasevi.description1")}
            description2={t("pojasevi.description2")}
            listItems={[]}
            imageSrc1={Pojasevi1}
            alt1="Pojasevi u boji"
            imageSrc2={Pojasevi2}
            alt2="Pojasevi u boji"
            imageSrc3={Pojasevi3}
            alt3="Pojasevi u boji"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                {t("pojasevi.btn")}
              </HashLink>
            }
          />
          {/* BOJANJA ČELJUSTI */}
          <UslugeBox
            imageSrc={BojanjeČeljusti1}
            altText="Bojanje čeljusti"
            title={t("celjusti.title")}
            description={t("celjusti.description1")}
            description2={t("celjusti.description2")}
            listItems={[]}
            imageSrc1={UgradnjaFelgi1}
            alt1="Bojanje čeljusti"
            imageSrc2={BojanjeČeljusti2}
            alt2="Bojanje čeljusti"
            imageSrc3={BojanjeČeljusti3}
            alt3="Bojanje čeljusti"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                {t("celjusti.btn")}
              </HashLink>
            }
          />
          {/* ZATAMNJIVANJE STAKLA */}
          <UslugeBox
            imageSrc={ZatamnjivanjeStakla1}
            altText="Zatamnjivanje stakla"
            title={t("stakla.title")}
            description={t("stakla.description1")}
            description2={t("stakla.description2")}
            listItems={[
              "Prednje vjetrobransko - 0%",
              "Prednja bočna - 30%",
              "Stražnja stakla - nema limita",
            ]}
            imageSrc1={ZatamnjivanjeStakla1}
            alt1="Zatamnjivanje stakla"
            imageSrc2={ZatamnjivanjeStakla3}
            alt2="Zatamnjivanje stakla"
            imageSrc3={ZatamnjivanjeStakla2}
            alt3="Zatamnjivanje stakla"
            link={
              <HashLink
                smooth
                to="/usluge/#section-form"
                onClick={() => handleClick("/usluge/#section-form")}
              >
                {t("stakla.btn")}
              </HashLink>
            }
          />
          {/*  */}
        </div>
        <p>{t("usluge.uslugeContact")}</p>
      </section>
    </>
  );
}
