import { useEffect } from "react";
import PolitikaBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import Main from "../Main/Main";
// Language
import { useTranslation } from "react-i18next";

export default function PolitikaPrivatnosti() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Autoconnect | Politika privatnosti";
  }, []);
  return (
    <>
      <Main imageUrl={PolitikaBackground} altText="Background privacy" />
      <section className="section">
        <h2>{t("privacyPolicy.title")}</h2>
        <div className="privacy-text">
          <p>{t("privacyPolicy.privacyBio1")}</p>
          <p>{t("privacyPolicy.privacyBio2")}</p>
          <p>{t("privacyPolicy.privacyBio3")}</p>
          <p>{t("privacyPolicy.privacyBio4")}</p>
          <p>{t("privacyPolicy.privacyBio5")}</p>
          <p>{t("privacyPolicy.privacyBio6")}</p>
        </div>
      </section>
    </>
  );
}
