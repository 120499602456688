import React from "react";
import ReactDOM from "react-dom/client";
import "./Styles/App.scss";
import { HashRouter, Routes, Route } from "react-router-dom";
import {
  Footer,
  Kontakt,
  Naslovnica,
  Navigation,
  Onama,
  OpciUvjeti,
  PolitikaPrivatnosti,
  Projekti,
  Servis,
  Usluge,
  ZastupstvaLine,
  SoundModul,
  Brabus,
  Techart,
  Hamann,
  Language,
} from "./Components";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <Navigation />
    <Language />
    <Routes>
      {/* Pages */}
      <Route path="/" element={<Naslovnica />} />
      <Route path="/o-nama" element={<Onama />} />
      <Route path="/servis" element={<Servis />} />
      <Route path="/usluge" element={<Usluge />} />
      <Route path="/projekti" element={<Projekti />} />
      <Route path="/kontakt" element={<Kontakt />} />
      <Route path="/opci-uvjeti" element={<OpciUvjeti />} />
      <Route path="/politika-privatnosti" element={<PolitikaPrivatnosti />} />
      <Route path="/sound-modul" element={<SoundModul />} />
      {/* Zastupstva */}
      <Route path="/zastupstva/brabus" element={<Brabus />} />
      <Route path="/zastupstva/hamann" element={<Hamann />} />
      <Route path="/zastupstva/techart" element={<Techart />} />
    </Routes>
    <ZastupstvaLine />
    <Footer />
  </HashRouter>
);
