import OnamaBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import OnamaImage from "../../../Images/ostalo/Autoconnect-prednja.jpg";
// Naš tim
import KrešimirBrkljačić from "../../../Images/zaposlenici/Krešimir-Brkljačić.jpg";
import DamirJakopić from "../../../Images/zaposlenici/Damir-Jakopić.jpg";
import NikolaVugrinec from "../../../Images/zaposlenici/Nikola-Vugrinec.jpg";
import IvicaVizec from "../../../Images/zaposlenici/Ivica-Vizec.jpg";
import NoProfile from "../../../Images/zaposlenici/No-profile.jpg";
//
import Main from "../Main/Main";
import { useState } from "react";
import { Helmet } from "react-helmet";
// Language
import { useTranslation } from "react-i18next";

//
export default function Onama() {
  return (
    <>
      <Helmet>
        <title>Autoconnect | O nama</title>
        <meta
          name="description"
          content="Tvrtka AutoConnect nastavlja tradiciju, zaljubljenicima u automobile vrhunskih performansi. Sinonim za visokosofisticirane prerade Mercedes Benz i Porsche ..."
        />
      </Helmet>
      <Main imageUrl={OnamaBackground} altText="Naslovna slika" />
      <OnamaDescirption />
      <div className="border"></div>
      <OnamaVrijednosti />
      <div className="border"></div>
      <OnamaTim />
    </>
  );
}

function OnamaDescirption() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const { t } = useTranslation();
  return (
    <>
      <section id="section-about" className="section">
        <h1>{t("about.aboutTitle")}</h1>
        <div className="about-grid">
          {/*   <!--  --> */}
          <div className="about-box">
            <h5>
              {t("about.aboutWelcome")} <span>AUTOCONNECT</span>
            </h5>
            <p>{t("about.aboutDear")}</p>
            <p>{t("about.aboutBio.aboutText1")}</p>
          </div>

          <div className="about-box">
            <img
              src={OnamaImage}
              alt="Autoconnect kuća"
              onClick={() => openImage(OnamaImage)}
            />
          </div>

          {/*  <!--  --> */}
        </div>
        <div className="about-box">
          <p>{t("about.aboutBio.aboutText2")}</p>
          <p>{t("about.aboutBio.aboutText3")}</p>
        </div>
        {/*  <!--  --> */}
        <div className="about-border"></div>
        {/*  <!--  --> */}
        <div className="about-box">
          <p>{t("about.aboutBio.aboutText4")}</p>
          <p>{t("about.aboutBio.aboutText5")}</p>
        </div>
        {/*  <!--  --> */}
        <div className="about-border"></div>
        {/*  <!--  --> */}

        <div className="about-box">
          <p>{t("about.aboutBio.aboutText6")}</p>
          <p>{t("about.aboutBio.aboutText7")}</p>
        </div>
        {/* <!--  --> */}
        <div className="about-border"></div>
        {/* <!--  --> */}
        <div className="about-box">
          <p>{t("about.aboutBio.aboutText8")}</p>
          <p>{t("about.aboutBio.aboutText9")}</p>
        </div>

        {/*  <!--  --> */}

        <p>{t("about.aboutEnjoy")}</p>
        <h6>~ {t("about.aboutDirector")}</h6>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}

function OnamaVrijednosti() {
  const { t } = useTranslation();
  return (
    <>
      <section id="section-vrijednosti" className="section">
        <h2>{t("about.aboutValue.aboutValueTitle")}</h2>
        <p>{t("about.aboutValue.aboutValueText")}</p>
        <ul className="vrijednosti-box">
          <li>
            <i className="fi fi-ss-badge-check"></i>
            {t("about.aboutValue.aboutValueTop")}
          </li>
          <li>
            <i className="fi fi-ss-diploma"></i>{" "}
            {t("about.aboutValue.aboutValueCert")}
          </li>
          <li>
            <i className="fi fi-ss-time-fast"></i>{" "}
            {t("about.aboutValue.aboutValueFast")}
          </li>
          <li>
            <i className="fi fi-ss-shield-check"></i>{" "}
            {t("about.aboutValue.aboutValueCare")}
          </li>
          <li>
            <i className="fi fi-ss-briefcase"></i>{" "}
            {t("about.aboutValue.aboutValuePro")}
          </li>
        </ul>
      </section>
    </>
  );
}

function OnamaTim() {
  const { t } = useTranslation();
  return (
    <>
      <section id="section-team" className="section">
        <h2>{t("about.ourTeam.teamTitle")}</h2>
        <div className="team-grid">
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={KrešimirBrkljačić} alt="Krešimir Brkljačić" />
            <div className="team-text">
              <h5>Krešimir Brkljačić</h5>
              <span>
                <i className="fi fi-ss-briefcase"></i>
                {t("about.ourTeam.director")}
              </span>
              <a href="mailto: kresimir@autoconnnect.hr">
                <i className="fi fi-ss-at"></i>kresimir@autoconnnect.hr
              </a>
              <a href="tel:0994676117">
                <i className="fi fi-ss-phone-call"></i>0994676117
              </a>
            </div>
          </div>

          {/*  <!--  --> */}
          <div className="team-box">
            <img src={DamirJakopić} alt="Damir Jakopić" />
            <div className="team-text">
              <h5>Damir Jakopić</h5>
              <span>
                <i className="fi fi-ss-users-alt"></i>
                {t("about.ourTeam.limarija")}
              </span>
              <a href="mailto: limarija@autoconnnect.hr">
                <i className="fi fi-ss-at"></i>limarija@autoconnnect.hr
              </a>
              <a href="tel: 012911500">
                <i className="fi fi-ss-phone-office"></i>012911500
              </a>
              <a href="tel: 0912343445">
                <i className="fi fi-ss-phone-call"></i>0912343445
              </a>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={NoProfile} alt="Ivana Čibarić" />
            <div className="team-text">
              <h5>Ivana Čibarić</h5>
              <span>
                <i className="fi fi-ss-computer"></i>
                {t("about.ourTeam.accounting")}
              </span>
              <a href="mailto: limarija@autoconnnect.hr">
                <i className="fi fi-ss-at"></i>knjigovodstvo@autoconnnect.hr
              </a>
              <a href="tel: 012911500">
                <i className="fi fi-ss-phone-office"></i>012911500
              </a>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={NikolaVugrinec} alt="Nikola Vugrinec" />
            <div className="team-text">
              <h5>Nikola Vugrinec</h5>
              <span>
                <i className="fi fi-ss-car-crash"></i>
                {t("about.ourTeam.autolimar")}
              </span>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={NoProfile} alt="Siniša Barberić" />
            <div className="team-text">
              <h5>Siniša Barberić</h5>

              <span>
                <i className="fi fi-ss-car-crash"></i>
                {t("about.ourTeam.autolimar")}
              </span>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={IvicaVizec} alt="Ivica Vizec" />
            <div className="team-text">
              <h5>Ivica Vizec</h5>

              <span>
                <i className="fi fi-ss-car-mechanic"></i>
                {t("about.ourTeam.mechanic")}
              </span>
            </div>
          </div>
          {/*  <!--  --> */}
          <div className="team-box">
            <img src={NoProfile} alt="Ivica Vizec" />
            <div className="team-text">
              <h5>Josip Medved</h5>

              <span>
                <i className="fi fi-ss-car-mechanic"></i>
                {t("about.ourTeam.autoMechanic")}
              </span>
            </div>
          </div>
          {/*  <!--  --> */}
        </div>
      </section>
    </>
  );
}
