import { useEffect, useState } from "react";
import Main from "../Main/Main";
import { HashLink } from "react-router-hash-link";
import { Link, useNavigate } from "react-router-dom";
// Language
import { useTranslation } from "react-i18next";

// Images
import Hamann1 from "../../../Images/zastupstva/hamann/Hamann.png";
import Hamann2 from "../../../Images/zastupstva/hamann/Hamann2.png";
import Hamann3 from "../../../Images/zastupstva/hamann/Hamann3.png";
import Hamann4 from "../../../Images/zastupstva/hamann/Hamann4.png";
import Hamann5 from "../../../Images/zastupstva/hamann/Hamann5.png";

export default function Hamann() {
  useEffect(() => {
    document.title = "Autoconnect | Hamann";
  }, []);
  return (
    <>
      <Main imageUrl={Hamann3} altText="Brabus" />
      <HamannText />
    </>
  );
}

function HamannText() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const { t } = useTranslation();
  const closeImage = () => {
    setShowImage("");
  };
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className="section">
        <h2>HAMANN</h2>
        <div className="zastupstva-text">
          <p>{t("zastupstva.hamann.hamannText1")}</p>
          <p>{t("zastupstva.hamann.hamannText2")}</p>
          <div className="zastupstva-grid">
            <img
              src={Hamann1}
              alt="Hamann"
              onClick={() => openImage(Hamann1)}
            />
            <img
              src={Hamann2}
              alt="Brabus"
              onClick={() => openImage(Hamann2)}
            />
          </div>

          <p>{t("zastupstva.hamann.hamannText3")}</p>
        </div>

        <div className="zastupstva-text">
          <h5> {t("zastupstva.hamann.hamannH5")}</h5>

          <p>{t("zastupstva.hamann.hamannText4")}</p>
          <div className="zastupstva-grid">
            <div>
              <img
                src={Hamann4}
                alt="Hamann"
                onClick={() => openImage(Hamann4)}
              />
              <img
                src={Hamann5}
                alt="Hamann"
                onClick={() => openImage(Hamann5)}
              />
            </div>
          </div>
          <p>{t("zastupstva.hamann.hamannText5")}</p>
          <Link to="https://www.hamann-motorsport.com/">
            https://www.hamann-motorsport.com/
          </Link>

          <div className="zastupstva-img-box"></div>
        </div>
        <div className="zastupstva-buttons">
          <HashLink
            smooth
            to="/zastupstva/brabus"
            className="btn"
            onClick={() => handleClick("/zastupstva/brabus")}
          >
            BRABUS &rarr;
          </HashLink>
          <HashLink
            smooth
            to="/zastupstva/techart"
            className="btn"
            onClick={() => handleClick("/zastupstva/techart")}
          >
            TECHART &rarr;
          </HashLink>
        </div>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
