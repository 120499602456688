import { useEffect } from "react";
import PolitikaBackground from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import Main from "../Main/Main";
// Language
import { useTranslation } from "react-i18next";

export default function PolitikaPrivatnosti() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Autoconnect | Opći uvjeti poslovanja";
  }, []);
  return (
    <>
      <Main imageUrl={PolitikaBackground} altText="Background privacy" />

      <section className="section" id="opci-uvjeti">
        <h2>{t("termsOfUse.title")}</h2>
        <div className="privacy-text">
          <p>{t("termsOfUse.termsBio1")}</p>
          <p>{t("termsOfUse.termsBio2")}</p>
          <p>{t("termsOfUse.termsBio3")}</p>
          <p>{t("termsOfUse.termsBio4")}</p>
          <p>{t("termsOfUse.termsBio5")}</p>
          <p>{t("termsOfUse.termsBio6")}</p>
          <p>{t("termsOfUse.termsBio7")}</p>
        </div>
      </section>
    </>
  );
}
