import { useEffect, useState } from "react";
import Main from "../Main/Main";
import { HashLink } from "react-router-hash-link";
import { Link, useNavigate } from "react-router-dom";
// Language
import { useTranslation } from "react-i18next";
// Images
import BrabusBackground from "../../../Images/zastupstva/brabus/Brabus2.png";
import BrabusImage from "../../../Images/zastupstva/brabus/Brabus.jpg";
import BrabusTextImage from "../../../Images/ostalo/Autoconnect-Brabus.jpg";
import BrabusTestiranje1 from "../../../Images/zastupstva/brabus/Brabus-testiranje.jpg";
import BrabusTestiranje2 from "../../../Images/zastupstva/brabus/Brabus-testiranje2.jpg";
import BrabusServis1 from "../../../Images/zastupstva/brabus/Brabus-servis1.jpg";
import BrabusServis2 from "../../../Images/zastupstva/brabus/Brabus-servis2.jpg";
import BrabusServis3 from "../../../Images/zastupstva/brabus/Brabus-servis3.jpg";
import BrabusServis4 from "../../../Images/zastupstva/brabus/Brabus-servis4.jpg";

export default function Brabus() {
  useEffect(() => {
    document.title = "Autoconnect | Brabus";
  }, []);
  return (
    <>
      <Main imageUrl={BrabusBackground} altText="Brabus" />
      <BrabusText />
    </>
  );
}

function BrabusText() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  const { t } = useTranslation();
  return (
    <>
      <section className="section">
        <h2>BRABUS</h2>
        <div className="zastupstva-text">
          <p>{t("zastupstva.brabus.brabusText1")}</p>
          {/*  */}
          <div className="zastupstva-grid">
            <img
              src={BrabusImage}
              alt="Brabus"
              onClick={() => openImage(BrabusImage)}
            />
            <img
              src={BrabusTextImage}
              alt="Brabus"
              onClick={() => openImage(BrabusTextImage)}
            />
          </div>
          {/*  */}
          <p>{t("zastupstva.brabus.brabusText2")}</p>
          <p>{t("zastupstva.brabus.brabusText3")}</p>
          <p>{t("zastupstva.brabus.brabusText4")}</p>
          <p>{t("zastupstva.brabus.brabusText5")}</p>
        </div>
        <div className="zastupstva-text">
          <h5>{t("zastupstva.brabus.brabusH5")}</h5>
          <p>{t("zastupstva.brabus.brabusText6")}</p>
          {/*  */}
          <div className="zastupstva-grid">
            <img
              src={BrabusTestiranje1}
              alt="Brabus testiranje"
              onClick={() => openImage(BrabusTestiranje1)}
            />
            <img
              src={BrabusTestiranje2}
              alt="Brabus testiranje"
              onClick={() => openImage(BrabusTestiranje2)}
            />
          </div>
          <p>{t("zastupstva.brabus.brabusText7")}</p>

          <Link to="https://www.brabus.com/">https://www.brabus.com/</Link>

          <div className="zastupstva-img-box">
            <img
              src={BrabusServis1}
              alt="Brabus"
              onClick={() => openImage(BrabusServis1)}
            />
            <img
              src={BrabusServis2}
              alt="Brabus"
              onClick={() => openImage(BrabusServis2)}
            />
            <img
              src={BrabusServis3}
              alt="Brabus"
              onClick={() => openImage(BrabusServis3)}
            />
            <img
              src={BrabusServis4}
              alt="Brabus"
              onClick={() => openImage(BrabusServis4)}
            />
          </div>
        </div>
        {/*  */}
        <div className="zastupstva-buttons">
          <HashLink
            smooth
            to="/zastupstva/hamann"
            className="btn"
            onClick={() => handleClick("/zastupstva/hamann")}
          >
            HAMANN &rarr;
          </HashLink>
          <HashLink
            smooth
            to="/zastupstva/techart"
            className="btn"
            onClick={() => handleClick("/zastupstva/techart")}
          >
            {" "}
            TECHART &rarr;
          </HashLink>
        </div>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
