import React from "react";
import { useTranslation } from "react-i18next";
import { initReactI18next } from "react-i18next"; // Import initReactI18next
import CroatiaFlag from "../../Images/ostalo/Croatia-Flag.png";
import UKFlag from "../../Images/ostalo/UK-Flag.png";
import i18n from "i18next";

i18n.use(initReactI18next).init({
  resources: {
    /* *****HRVATSKI***** */
    hr: {
      translation: {
        // NAVIGATION:
        navigation: {
          home: "NASLOVNA",
          about: "O NAMA",
          servis: "SERVIS",
          services: "USLUGE",
          projects: "PROJEKTI",
          contact: "KONTAKT",
        },
        // HOME
        home: {
          homeTitle: "DOBRODOŠLI U SVIJET VOZILA",
          homeBio:
            "Autoconnect, u kojem uz popravak Vašeg vozila možete razgledati automobile koji predstavljaju sam vrh aktualnih tehnoloških i dizajnerskih trendova...",
          aboutBtn: "O nama",
          ratingsTitle: "KLIJENTI O NAMA",
          rating1: "Sve na nivou , jako pristupacni i korektni!",
          rating2:
            " Ljubazno osoblje, nisam čekao termin. Kvalitetna usluga. I dobra cijena. Za sada sve super. Preporučam...",
          rating3:
            "Odličan servis sa profesionalnim djelatnicima i dobrim cijenama. Sve pohvale i preporuke.",
          ratingMore: "Više",
          autoconnectOnline: "Online naručivanje",
          autoconnectexp: "Dugogodišnje iskustvo",
          autoconnectcertificate: "Certificirano osoblje",
          autoconnectprice: "Najbolje cijene servisa",
          autoconnectwarranty: "12 mjeseci garancije na servis",
        },
        // O NAMA
        about: {
          aboutTitle: "O NAMA",
          aboutWelcome: "DOBRODOŠLI NA NAŠU STRANICU TVRTKE",
          aboutDear: "Poštovani posjetitelji,",
          aboutBio: {
            aboutText1:
              "prije nego se pobliže predstavimo, dopustite da Vam zaželimo dobrodošlicu u svijet automobila, tuninga, vrhunskih dorada i svih onih uzbuđenja koja nam pruža fascinacija automobilima. Jednom riječju, dobrodošli u AutoConnect svijet.",
            aboutText2:
              "Ljubiteljima automobila na poznatoj adresi, Zagrebačka 33 u Sesvetama, smjestio se prodajno servisni centar tvrtke Autoconnect u kojem uz popravak Vašeg vozila možete razgledati automobile koji predstavljaju sam vrh aktualnih tehnoloških i dizajnerskih trendova (Mercedes Benz, Porsche, Land Rover…), ali i automobile koji potječu iz nekih prošlih, nezaboravljenih vremena.",
            aboutText3:
              "Automobile koji su svojim inovativnim rješenjima, bezvremenskim dizajnom te romantikom i neospornim šarmom minulih dana, determinirali razvoj autoindustrije kakvu danas poznajemo.",
            aboutText4:
              "Prodajno servisni centar tvrtke Autoconnect, na 1300m2 raspoređenih na dva kata, uz moderne i oldtimer automobile nudi i veliki izbor dodatne opreme uključujući i čitav asortiman renomiranih tvrtki koje zastupamo, prije svega BRABUS, TECHART i HAMMAN i po tome smo jedinstveni na hrvatskom auto tržištu.",
            aboutText5:
              "Tvrtka AutoConnect nastavlja tradiciju, zaljubljenicima u automobile vrhunskih performansi. Sinonim za visokosofisticirane prerade Mercedes Benz i Porsche automobila, prije svega legendarne G klase od kojih one najljepše, često viđamo na cesti.",
            aboutText6:
              "Uz prodaju i servis za sva vozila, kod nas je moguće obaviti i sve vrste limarskih i lakirerskih radova te izvršiti popravak vozila po prijavi štete.",
            aboutText7:
              "U sustavu smo certificiranog Mercedes Benz servisa, uključujući mogućnost upisa u digitalnu servisnu bazu. Servisna se knjižica vozila, po obavljenom servisu, potvrđuje i registrira u Mercedes Benz centralnom sustavu podataka.",
            aboutText8:
              "Dugogodišnje iskustvo, ulaganja u nova znanja i tehnologije u korak sa svjetskim trendovima, čine tvrtku AutoConnect pouzdanim servisnim partnerom kojem se klijenti rado vraćaju.",
            aboutText9:
              "Iskustvo naših djelatnika, oprema našeg prodajno servisnog centra te posvećenost visokim standardima naših principala snaga je koja obvezuje, ali i jamči vrhunsku i visokokvalitetnu uslugu prilikom kupnje i servisiranja Vašeg automobila. Zato s veseljem očekujemo Vaš posjet.",
          },
          aboutEnjoy: "Dopustite da Vas oduševimo…",
          aboutDirector: "KREŠIMIR BRKLJAČIĆ, direktor",
          // About vrijednosti
          aboutValue: {
            aboutValueTitle: "NAŠE VRIJEDNOSTI",
            aboutValueText:
              "Prolazimo opsežnu tvorničku obuku kako bismo Vam mogli pružiti znanje potrebno za kvalitetno servisiranje, tuning & doradu vozila.",
            aboutValueTop: "Vrhunska i visokokvalitetna usluga",
            aboutValueCert: "Kvalificirano i školovano osoblje",
            aboutValueFast: "Brza nabava dijelova za servis",
            aboutValueCare: "Vrhunska briga o klijentima",
            aboutValuePro: "Profesionalni servis",
          },
          // Our Team
          ourTeam: {
            teamTitle: "NAŠ TIM",
            director: "Direktor",
            limarija: "Voditelj limarije",
            accounting: "Voditeljica knjigovodstva",
            autolimar: "Autolimar",
            mechanic: "Mehaničar",
            autoMechanic: "Automehaničar",
          },
        },
        // SERVIS
        servis: {
          servisTitle: "SERVISNI CENTAR VOZILA",
          servisBio:
            " Jeste li spremni pružiti svom vozilu uslugu koju traži? Zakažite održavanje ili popravak vozila upravo ovdje. Znamo da nema ništa frustrirajuće nego biti bez svog vozila dok se ne popravi. Zato imamo osoblje koje se ističe u pružanju vrhunskog održavanja i popravka i sposobno je to učiniti brzo.",
          servisList: "NAŠE USLUGE UKLJUČUJU",
        },
        // USLUGE
        usluge: {
          uslugeTitle: "NAŠE USLUGE",
          uslugeBio1: "Napravite od svog ljubimca sportsku varijantu.",
          uslugeBio2:
            "Evo što vam još sve nudimo kako bi svome vozilu unaprijedili estetiku i kvalitetu. Iskustvo naših djelatnika i oprema našeg prodajno-servisnog centra jamče vrhunsku i visokokvalitetnu uslugu kod kupnje i servisiranja vašeg automobila.",
          uslugeBio3: "Trajanje i cijena variraju, ovisno o modelu automobila.",
          uslugeContact:
            "Kontaktirat ćemo Vas kako bismo potvrdili Vaš termin za uslugu.",
        },
        // Sound modul
        soundModul: {
          title: "SOUND MODUL",
          description1:
            "Želite li svom vozilu pružiti više osjećaja, ili Vaš standardni ispušni sustav ne proizvodi zvuk koji želite? Vozite li električno ili hibridno vozilo i možda uopće nemate buke u vožnji? Znamo koliko taj mali detalj može frustrirati i zato imamo jednostavno riješenje.",
          description2:
            "Pomoću aplikacija za pametni telefon Panthera Connect možete konfigurirati Leo Active Sound System kako god Vi želite. Dodirom gumba glasnoću, zvuk, brzinu, dodijeliti zvučni profil i aktivirati efekte.",
          listItems: ["Zvuk", "Ugradnja", "Bass"],
          moreBtn: "Opširnije",
        },
        // Tapeciranje
        tapeciranje: {
          title: "TAPECIRANJE",
          description1:
            "Kod tapeciranja koristimo tradiciju i modernu tehnologiju kako bismo podigli izgled i atmosferu unutar automobila na potpuno višu razinu. Osim klasičnog, modernog i sportskog dizajna nudimo restauraciju i personaliziranje, odnosno dizajn po vašoj želji.",
          description2:
            "Materijali koji Vam stoje na raspolaganju su koža, skaj, eko koža, alcantara, štof, velur i drugo.",
          servicesList: [
            "Djelomično ili kompletno tapeciranje sjedala",
            "Obnova konstrukcije sjedala",
            "Presvlačenje volana u kožu ili imitaciju kože",
            "Tapeciranje naslona za ruku",
            "Obnova konstrukcije sjedala",
          ],
          btn: "Naruči se",
        },
        // Wrap
        wrap: {
          title: "WRAP",
          description1:
            "Omatanje vozila nudi mnoge benefite. Možda želite zaštitu od vanjskih čimbenika ili promjeniti izgled vozila i pokazati svoju kreativnost. Imamo široku ponudu boja, tekstura i finiša uz koje Vaš automobil postaje unikat.",
          description2:
            "Prije svega važno je da se cijeli proces napravi profesionalno. Time dobivate dugotrajan učinak koji štiti Vaš auto u nadolazećim godinama.",
          servicesList: "",
          btn: "Naruči se",
        },
        // Ugradnja felgi
        felge: {
          title: "UGRADNJA FELGI",
          description1:
            "Stručni smo u dobavljanju i ugradnji alu felgi. Između svih veličina, boja i uzoraka, savršen balans između stila i performanse pronaći ćete kod nas.",
          description2:
            "Širok asortiman je vrlo važan, jer su kotači vrlo specifičan i tehnički složen dio vozila i sigurnost je na prvom mjestu, zato se kod nas možete posavjetovati sa stručnjacima koji imaju godine iskustva u industriji.",
          servicesList: "",
          btn: "Naruči se",
        },
        // Pojasevi u boji
        pojasevi: {
          title: "POJASEVI U BOJI",
          description1:
            "Bilo kakva boja miče monotoniju iz interijera vozila, tako da je u teško otići u krivome smjeru. U ponudi imamo sve boje i uzorke, ali samo najkvalitetnije materijale.",
          description2:
            "Uz uslugu tapeciranja i šavova u boji interijer može dati potpuno novo iskustvo putovanja.",
          btn: "Naruči se",
        },
        // Bojanja čeljusti
        celjusti: {
          title: "BOJANJA ČELJUSTI",
          description1:
            "Bilo kakva boja miče monotoniju iz interijera vozila, tako da je u teško otići u krivome smjeru. U ponudi imamo sve boje i uzorke, ali samo najkvalitetnije materijale.",
          description2:
            "Uz uslugu tapeciranja i šavova u boji interijer može dati potpuno novo iskustvo putovanja.",
          btn: "Naruči se",
        },
        // Zatamnjivanje stakla
        stakla: {
          title: "ZATAMNJIVANJE STAKLA",
          description1:
            "Tražite li zaštitu od svjetla ili jednostavno suptilniju atmosferu na zadnjem sjedištu, važno je da tamljenje stakala obavi vješta ruka.",
          description2:
            "Zaštitna folija zaustavlja 99% štetnih zračenja, štiti putnike, interijer. Osim što prevenira rasprskavanje komadića stakala u slučaju nesreće i štiti od ozljeda, također podiže cijelokupan izgled vozila.",
          servicesList: [
            "Prednje vjetrobransko - 0%",
            "Prednja bočna - 30%",
            "Stražnja stakla - nema limita",
          ],
          btn: "Naruči se",
        },
        // PROJEKTI
        projekti: {
          title: "NAŠI PROJEKTI",
          galleryTitle: "GALERIJA AUTA",
          projectTitle: "Na ovom vozilu doradili smo:",
          moreBtn: "Ostali projekti",
          brabusAMG: {
            list1: "Karbonski paket i zvuk",
            list2: "Pragovi stražnjeg difuzora",
            list3: "Mat crna folija",
            list4: 'Brabus aluminijske felge od 21"',
          },
          fiatAbarath: {
            list1: "Pojasi u boji",
            list2: "Farbanje čeljusti",
            list3: "Alcantara obloge vratiju",
            list4: "Ispušni sistem Akrapović",
          },
          brabusG800: {
            list1: '23" naplatci od kovane legure',
            list2: "Kromirane ispušne cijevi",
            list3: "Dorada interijera",
            list4: "Dodavanje Brabusove grafike",
          },
          mercedesA250: {
            list1: "Brabus difuzor",
            list2: "Ispušni sistem",
          },
          smartEQ: {
            list1: "Prednji spojler",
            list2: "Bočni pragovi",
          },
          smart: {
            list1: "Wrap",
          },
        },
        // CONTACT
        contact: {
          contactTitle: "KONTAKT",
          contactMonFri: "Pon-Pet",
        },
        // FORM
        form: {
          formTitle: "KONTAKTIRAJTE NAS",
          formOrder: "NARUČITE SE:",
          formReservation: "REZERVIRAJTE TERMIN:",
          formName: "Ime i prezime",
          formNamePH: "Ime i prezime",
          formEmailPH: "primjer@gmail.com",
          formPhone: "Mobitel",
          // Select
          // Select servis
          formSelectServis: "Odaberite servis:",
          formSelectServices: "Odaberite uslugu:",
          formSelect: "Odaberite:",
          formSelectRepair: "Generalni popravak automobila",
          formSelectMaintenance: "Preventivno održavanje automobila",
          formSelectAir: "Servis klima uređaja i grijača",
          formSelectCooling: "Popravak rashladnog sustava i hladnjaka",
          formSelectEngineOil: "Zamjena motornog ulja",
          formSelectOilFilter: "Zamjena filtera za ulje",
          formSelectBrakes: "Popravak kočnica",
          formSelectDiagnostics: "Dijagnostika motora",
          formSelectTransmission: "Usluge prijenosa",
          // Select Usluge
          formSelectSound: "Sound modul",
          formSelectUpholstery: "Tapeciranje",
          formSelectWrap: "Wrap",
          formSelectRims: "Ugradnja felgi",
          formSelectBelts: "Pojasevi u boji",
          formSelectPainting: "Bojanja čeljusti",
          formSelectGlass: "Zatamnjivanje stakla",
          formSelectOther: "Ostalo",
          // Car / Model
          formCarModel: "Vozilo/model",
          formCarModePH: "Vozilo / model",
          // Date
          formDate: "Odaberite datum:",
          // Message
          formSend: "Pošaljite poruku",
          formSendPH: "Vaša poruka...",
          // Submit
          formSubmit: "POŠALJI",
          // SENT & ERROR
          formThankYouTitle: "PORUKA POSLANA! ",
          formThankYouText: "Kontaktirati ćemo Vas u najkraćem mogućem roku!",
          formErrorTitle: "DOŠLO JE DO POGREŠKE! ",
          formErrorText: "Pokušajte ponovo kasnije ili nas nazovite ",
        },
        // FOOTER
        footer: {
          footerCountry: "Hrvatska",
          footerContacts: "KONTAKTI",
          footerInfoTitle: "PODACI O TVRTKI",
          footerInfoCourt: "Sud upisa u registar: Trgovački sud u Zagrebu",
          footerInfoCourt2: "otvoren u Privredna banka Zagreb d.d.",
          footerInfoCourt3:
            "Temeljni kapital: 20.000,00 kn, uplaćen u cijelosti",
          footerLinksTitle: "LINKOVI",
          footerTerms: "Opći uvijeti poslovanja",
          footerPolicy: "Politika privatnosti",
        },
        // TERMS & PRIVACY
        termsOfUse: {
          title: "OPĆI UVJETI POSLOVANJA",
          termsBio1:
            "Korisnici i kupci su dužni prije početka korištenja web stranice (www.autoconnect.hr) pružatelja usluga Autoconnect sa sjedištem u Zagrebu, Zagrebačka 33 - 10360 Sesvete upoznati se s Općim uvjetima korištenja web stranice. Ukoliko imaju dodatnih pitanja ili nejasnoća vezanih uz uvjete korištenja, mogu se obratiti na adresu e-pošte knjigovodstvo@autoconnect.hr",
          termsBio2:
            "Slanjem upita, potpisom ugovora ili neposrednim kontaktom, suglasni ste da Autoconnect dobrovoljno stavljate na raspolaganje svoje i podatke trgovačkog društva koje predstavljate ili zastupate. Dozvoljavate da se isti koriste u cilju zaštite osobnih interesa u svim poslovima koje ugovarate s Autoconnect-om.",
          termsBio3:
            "Autoconnect se obavezuje pružati zaštitu osobnim podacima kupaca, na način da prikuplja samo nužne, osnovne podatke o kupcima/korisnicima koji su nužni za ispunjenje naših poslovnih obveza.",
          termsBio4:
            "Svi se podaci o korisnicima čuvaju i dostupni su samo podaci nužni za obavljanje posla. Svi djelatnici Autoconnect-a i poslovni partneri odgovorni su za poštivanje načela zaštite privatnosti.",
          termsBio5:
            "Skupljanje i obrada osobnih podataka u svrhu realizacije dogovorenih poslova uključuje i prosljeđivanje osobnih podataka klijenta trećim osobama i trgovačkim društvima u Republici Hrvatskoj i inozemstvu. Trećim osobama smatraju se sve fizičke osobe i trgovačka društva koje su neophodne za realizaciju ugovorenih poslova.",
          termsBio6:
            "Vanjske poveznice Autoconnect sadržava linkove/vanjske poveznice na web stranice izvan vlastite internetske stranice. Autoconnect linkove objavljuje u dobroj namjeri i ne može se smatrati odgovornim za sadržaje izvan ove internetske stranice.",
          termsBio7:
            "Dužnost i obaveza korisnika je koristiti web stranicu u skladu s pozitivnim propisima te općim moralnim i etičkim načelima. Autoconnect ima pravo u svakom trenutku vršiti kontrolu sadržaja web stranice kako bi osigurao poštivanje Općih uvjeta i pozitivnih propisa. Izmjene uvjeta korištenja važeće su odmah po objavi na web stranici www.autoconnect.hr",
        },
        privacyPolicy: {
          title: "POLITIKA PRIVATNOSTI",
          privacyBio1:
            "Autoconnect d.o.o., OIB: 27909788832, Zagrebačka 33, Zagreb-Sesvete- u svom poslovanju prikuplja i obrađuje osobne podatke svojih korisnika usluga te poduzima mjere kako bi obrada osobnih podataka bila zakonita, poštena i transparentna. Prikupljanje, obrada i uporaba podataka provode se u skladu s propisima o zaštiti podataka, te pojmovi koji se koriste u ovoj Izjavi imaju značenje određeno Uredbom EU 2016/679.",
          privacyBio2:
            "Ovu Internet stranicu možete pregledavati i koristiti anonimno jer se tom prilikom ne prikupljaju podaci koji bi omogućili Vašu identifikaciju kao pojedinca, te niste obvezni pružiti nam Vaše osobne podatke kao uvjet korištenja stranice.",
          privacyBio3:
            "Upisom osobnih podataka na za to predviđena polja na Internet stranicama potvrđujete da ste svoje osobne podatke dobrovoljno stavili na raspolaganje te da dozvoljavate da se isti koriste u svrhu u koju su podaci dani.",
          privacyBio4:
            "Vaše podatke obrađujemo i koristimo u svrhu izvršenja ugovora u kojem ste stranka i kako bi se poduzele radnje na Vaš zahtjev prije sklapanja ugovora ( npr. rezerviranje i najam vozila i sl.) te ako je obrada nužna radi poštovanja pravnih i zakonskih obveza Autoconnect-a (npr. vođenje računovodstvenih poslova, dostava Vaših podataka nadležnom tijelu ako je vozilom koje ste koristili počinjen prekršaj i sl.)",
          privacyBio5:
            "Podatke koje smo prikupili od Vas i o Vama ostaju u bazama Autoconnect-a. Vaše podaci se mogu dostaviti trećim stranama kada je to naša obaveza prema zakonu ili kao odgovor na pravni postupak odnosno na zahtjev nadležnih institucija za provedbu zakona u vezi s prekršajnim, kaznenim ili sudskim postupcima i radi traženja odgovarajućih pravnih lijekova i ograničavanja štete koja nam može biti nanesena.",
          privacyBio6:
            "Podaci za koje zakonima ili drugim propisima nije propisan rok pohrane, pohranjuju se za razumno razdoblje imajući u vidu kategoriju podataka i svrhu u koju su isti prikupljeni. Podaci prikupljeni u određenu svrhu koristit će se samo u tu svrhu te nakon isteka razumnog vremenskog razdoblja i nakon što se ta svrha ispuni više neće biti aktivno pohranjeni.",
        },
        // SOUND MODUL
        soundModulPage: {
          title1: "SOUND MODUL",
          soundText1:
            "Želite li svom vozilu pružiti više osjećaja, ili Vaš standardni ispušni sustav ne proizvode zvuk koji želite?",
          soundText2:
            "Vozite li električno ili hibridno vozilo i možda uopće nemate buke u vožnji?",
          soundText3: "Naš distributer Panthera za Vas ima riješenje.",
          soundH5: "Preuzmite Panthera aplikaciju:",
          appStore: "na App Store",
          googlePlay: "na Google Play",
          soundText4:
            "Pomoću aplikacija za pametni telefon Panthera Connect možete konfigurirati Leo Active Sound System prema Vašim željama.",
          // Instalacija
          title2: "INSTALACIJA",
          soundText5:
            "Zahvaljujući našem OBD rješenju, montaža je brza i jednostavna. Zvučnik aktivnog zvučnog sustava može se ugraditi u vozilo, kao i vani, po želji. Po potrebi se mogu instalirati do četiri zvučnika.",
          soundText6:
            "Snaga se napaja preko paljenja plus i trajnog plusa putem isporučenog adaptera (plug and play) na kutiji s osiguračima u vozilu.",
          soundText7:
            "Tada se OBD-II adapter uključuje u vozilo. Ostali sustav radiji bežično.",
          btn: "Naruči se",
          // Konfiguracija
          title3: "KONFIGURACIJA",
          soundText8:
            "Leo Active Sound System isporučuje se unaprijed konfiguriran sa standardnim postavkama i stoga je širok za upotrebu.",
          soundText9:
            "Za individualno zvučno iskustvo preporučujemo postavljanje pomoću aplikacija Panthera Connect 4.0.",
          soundText10:
            "Ovdje možete prilagoditi 8 različitih zvučnih profila i odabrati zvučne datoteke.",
          soundText11:
            "Glasnoća, brzina, prazan hod, prekidi paljenja – sve se može jednostavno i jasno postaviti pomoću aplikacija Panthera Connect 6.0.",
          soundText12:
            "Uz to, uvijek ostanite u toku i redovito se ažurirajte. Ažuriranje se jednostavno instalira putem aplikacija.",
        },
        // ZASTUPSTVA
        zastupstva: {
          title: "ZASTUPSTVA",
          brabus: {
            brabusText1:
              "Više od 40 godina Brabus razvija i izrađuje superautomobile visokih performansi i transformira vozila marke Mercedes-Benz u moderna, jedinstvena vozila izrađena u Njemačkoj.",
            brabusText2:
              "Tijekom četiri desetljeća, BRABUS je u određenom smislu zrcalio performanse svakog BRABUS motora: postoji snažan potisak prema naprijed i taj potisak ide dalje i dalje. Sve više i više zapremine, više konjskih snaga, više okretnog momenta. Sve više i više inovacija.",
            brabusText3:
              "Sve više i više internih sastanaka, više sigurnosti, više ekološke prihvatljivosti. Sve više i više ekskluzivnosti. I sve više zapisa.",
            brabusText4:
              "Strast prema automobilima, predanost izvedbi, želja za savršenstvom, težnja za superlativom, ali i osjećaj za estetiku i funkcionalnost pretvaraju serijske automobile u BRABUS automobile.",
            brabusText5:
              "Umjetnost je stvaranja automobila velikih i malih odražavaju vrhunsku tehnologiju i sigurnost – vrijeme za vremenom. Jednostavno vrhunsko.",
            brabusH5: "JEDAN MARKA – JEDAN TIM",
            brabusText6:
              "Automobili visokih performansi, vrhunska podešavanja i stil života za Mercedes-Benz i smart domena su BRABUS-a. BRABUS GmbH, osnovan 1977, najveći je neovisni svjetski specijalist za preradu automobila i proizvođač ekskluzivnih superautomobila.",
            brabusText7:
              "Svjetski poznati tuner i proizvođač automobila nudi prilagođena rješenja za bilo koji zahtjev: asortiman proizvoda BRABUS obuhvaća nadograđene motore i elegantno stilizirane setove za poboljšanje aerodinamike do visokokvalitetnih aluminijskih naplataka, sportskih, ali udobnih sportskih ovjesa i ekskluzivnih interijera na najvišoj razini sjedišta tvrtke BRABUS nalazi se u Bottropu, a osim prostranih prodajnih salona u njemu se nalaze i marketing i prodaja, administracija, kao i inženjerski i proizvodni odjel.",
          },
          hamann: {
            hamannText1:
              "HAMANN Motorsport nudi više od pukog podešavanja vozila: od 1986. godine naziv je razvoj i prilagodba vozila na najvišoj razini.",
            hamannText2:
              "Ispunite svoje automobilske snove i oslobodite se na stručnost i iskustvo HAMANN Motorsporta u dizajnerskim, kvalitativnim, performansama i načinu života.",
            hamannText3:
              "Najbolje podešavanje vozila-prepustite se inspiraciji Dajte se nadahnuti – našim ekskluzivnim programima za vrhunske modele marke BMW , Range Rover , Lamborghini, Mercedes–Benz i Porsche , posebno Macan i Cayenne. Ugađanje vozila na najvišoj razini!",
            hamannH5:
              "EKSKLUZIVNO HAMANNOVO UGAĐANJE ZA VRLO POSEBNE AUTOMOBILE",
            hamannText4:
              "Ako tražite nešto posebno, individualno i jedinstveno, zasigurno ćete ga pronaći na HAMANN Motorsport. Program ugađanja uključuje moćne koncepte širokog karoserije i sportske aerodinamične komplete s mnogim stvarnim karbonskim elementima, optimizacije šasije orijentirane na performanse, visokokvalitetne lagane aluminijske naplatke i poboljšanja performansi i još mnogo toga.",
            hamannText5:
              "Hamann Tuning također nudi nešto posebno s komponentama za Panameru, Cayenne ili 911 (997). Hamannovi razvoji za Mercedes-Benz G-modele G55, G63 i G65, kao i SLS AMG R197 i SLS AMG C197 također su vrijedne pažnje. Također i marka Lamborghini tim Hamanna prihvaćen je konceptom individualizacije i visokokvalitetnih komponenata za Aventador, Gallardo, LP 640 ili Murciélago. HAMANN Motorsport također čini automobilsku luksuznu klasu nepogrešivim – bez obzira na to što su Rolls Royce Wraithu, Drophead Coupéu, Phantomu ili Ghostu ili Bentleyu Continental GT. Kupci u cijelom svijetu vjeruju u kompetentnost HAMANN Motorsporta – mogućnosti prilagodbe ne postoje, Hamannov tim također omogućava nemoguće.",
          },
          techart: {
            techartText1:
              "Dobrodošli u svijet premium prilagodbi za vaš Porsche model. Vrhunska prilagodba za vaš Porscheov model. Od 1987. god.",
            techartText2:
              "TECHART je međunarodna premium marka za individualnu prilagodbu svakog modela Porschea. Potaknuti posebnom potražnjom za izvrsnim dizajnom i najvišom razinom razvojne stručnosti. I posljednje, ali ne najmanje važno, odgovornim rukovanjem osjećajima i razumom. Za trajno uživanje u više individualnosti.",
            techartText3:
              "Naše osnovno načelo: OEM kvaliteta izrađena u Njemačkoj – vidljiva i nevidljiva. Svaki pravi TECHART proizvod rezultat je ove maksime.",
            // List 1
            techartList1:
              "Vlastiti razvoj dizajna i izrada modela na lokaciji Leonberg",
            techartList2:
              "Aerodinamičke probne vožnje i optimizacija u zračnom tunelu",
            techartList3: "CAD-podržani razvojni proces",
            techartList4:
              "Precizna izrada alata za serijsku proizvodnju u poliuretanu-RIM i ugljiku",
            techartList5: "Odobrenja tipa, certifikati dijelova i homologacija",
            techartList6:
              "Provjera i ovjera u ispitivanjima sudara i opterećenja materijala",
            techartList7: "Ugradnja i servisiranje bez problema",
            techartList8:
              "Komponentni i vozački ispiti za maksimalne performanse i prikladnost za svakodnevnu upotrebu",
            techartList9:
              "Kvalitetne sirovine kao što su PU-RIM, ugljična vlakna, ručno ubrana koža i dragocjeno drvo",
            techartH5: "TECHART je sloboda",
            techartText4:
              "Svatko ima svoj pogled na stvari. Vlastite vještine i talenti. Vlastiti način razmišljanja i življenja. Vaša vlastita percepcija slobode i individualnosti. – Dakle, ako odlučite dio svoje osobnosti prenijeti na svoj Porscheov model, što bi za vas bila prava individualnost? Koja bi bila vaša mjera jedinstvenosti?",
            techartText5:
              "Uživajte u slobodi naglašavanja upravo onih karakternih osobina u vašem vozilu koje za vas znače individualnost i jedinstvenost. S TECHART-om imate na raspolaganju gotovo neograničen raspon mogućnosti.",
            // List 2
            techartList10:
              "U zračnom tunelu testirani su aerodinamični i stilski paketi",
            techartList11:
              "Površine i dijelovi od karbonskih vlakana za tijelo i unutrašnjost",
            techartList12:
              "Snažna poboljšanja performansi motora i sustavi dinamike vožnje",
            techartList13: "Ispušni sustavi za zvuk koji oduzima dah",
            techartList14:
              "Kotači od lakih legura s priključkom od 5 rupa i središnjom bravom",
            techartList15: "Unutarnja dorada i ručno izrađeni volani",
            techartText6:
              "Aerodinamične i styling opcije TECHART naglašavaju sportsku orijentaciju individualiziranih Porscheovih vozila iz svakog kuta. Tipično TECHART: razlikovanje od serije je nepogrešivo. A opet toliko rezerviran da je zadržana Porscheova dizajnerska linija.",
            techartText7:
              "Izbor TECHART vanjskih opcija raznolik je. To uključuje cijele prednje i stražnje dijelove koji se neprimjetno uklapaju u oblik tijela. Prednji spojler i stražnje krilo koji na prvi pogled daju sportsku izjavu.",
            techartText8:
              "Popraćen gotovo neograničenim izborom komponenata karoserije TECHART, kao što su bočne suknje, poklopci motora, krovni spojleri, kao i vanjski dijelovi obloga i površinske obrade za serijske dijelove.",
          },
        },
      },
    },
    /* *****ENGLISH***** */
    en: {
      translation: {
        // NAVIGATION
        navigation: {
          home: "HOME",
          about: "ABOUT",
          servis: "CAR SERVICE",
          services: "SERVICES",
          projects: "PROJECTS",
          contact: "CONTACT",
        },
        // Home:
        home: {
          homeTitle: "WELCOME TO THE WORLD OF VEHICLES",
          homeBio:
            "Autoconnect, in which, in addition to repairing your vehicle, you can view cars that represent the very top of current technological and design trends...",
          aboutBtn: "About us",
          ratingsTitle: "CLIENTS ABOUT US",
          rating1: "Everything at the level, very accessible and correct!",
          rating2:
            "Friendly staff, I didn't wait for an appointment. Quality service. And a good price. So far so good. Recommend....",
          rating3:
            "Excellent service with professional staff and good prices. All praise and recommendations.",
          ratingMore: "More",
          autoconnectOnline: "Online ordering",
          autoconnectexp: "Many years of experience",
          autoconnectcertificate: "Certified staff",
          autoconnectprice: "The best service prices",
          autoconnectwarranty: "12 months service warranty",
        },
        // ABOUT US
        about: {
          aboutTitle: "ABOUT US",
          aboutWelcome: "WELCOME TO OUR WEBSITE",
          aboutDear: "Dear visitors,",
          aboutBio: {
            aboutText1:
              "before we introduce ourselves in more detail, allow us to welcome you to the world of cars, tuning, top finishing and all the excitement that the fascination with cars provides us. In a word, welcome to the AutoConnect world.",
            aboutText2:
              "For car lovers, at the well-known address, Zagrebačka 33 in Sesvete, there is a sales and service center of the company Autoconnect where, in addition to repairing your vehicle, you can view cars that represent the very top of current technological and design trends (Mercedes Benz, Porsche, Land Rover...), as well as cars which originate from some past, unforgettable times.",
            aboutText3:
              "Cars that determined the development of the auto industry as we know it today with their innovative solutions, timeless design and romance and indisputable charm of the past.",
            aboutText4:
              "The sales and service center of the company Autoconnect, on 1300m2 spread over two floors, offers a large selection of accessories in addition to modern and oldtimer cars, including the entire range of renowned companies that we represent, primarily BRABUS, TECHART and HAMMAN, which makes us unique on the Croatian car market.",
            aboutText5:
              "The AutoConnect company continues the tradition, for lovers of top performance cars. A synonym for highly sophisticated modifications of Mercedes Benz and Porsche cars, above all the legendary G class, of which the most beautiful ones are often seen on the road.",
            aboutText6:
              "In addition to sales and service for all vehicles, we can also perform all types of bodywork and paintwork, as well as repair vehicles upon damage notification.",
            aboutText7:
              "We are in the system of certified Mercedes Benz service, including the possibility of registering in the digital service base. After the service, the vehicle's service booklet is confirmed and registered in the Mercedes Benz central data system.",
            aboutText8:
              "Many years of experience, investments in new knowledge and technologies in step with global trends, make the company AutoConnect a reliable service partner to which clients are happy to return.",
            aboutText9:
              "The experience of our employees, the equipment of our sales and service center, and the dedication to high standards of our principals is the force that binds, but also guarantees top-quality and high-quality service when buying and servicing your car. That's why we look forward to your visit.",
          },
          aboutEnjoy: "Let us delight you...",
          aboutDirector: "KREŠIMIR BRKLJAČIĆ, director",
          // About vrijednosti
          aboutValue: {
            aboutValueTitle: "OUR VALUES",
            aboutValueText:
              "We undergo extensive factory training in order to be able to provide you with the knowledge necessary for quality servicing, tuning & finishing of vehicles.",
            aboutValueTop: "Superior and high-quality service",
            aboutValueCert: "Qualified and trained staff",
            aboutValueFast: "Quick supply of parts for service",
            aboutValueCare: "Superior customer care",
            aboutValuePro: "Professional service",
          },
          // Our Team
          ourTeam: {
            teamTitle: "OUR TEAM",
            director: "Director",
            limarija: "Sheet Metal Supervisor",
            accounting: "Accounting Manager",
            autolimar: "Auto Body Repair Technician",
            mechanic: "Mechanic",
            autoMechanic: "Auto mechanic",
          },
        },
        // SERVIS
        servis: {
          servisTitle: "VEHICLE SERVICE CENTER",
          servisBio:
            "Are you ready to give your vehicle the service it is looking for? Schedule vehicle maintenance or repair right here. We know there's nothing more frustrating than being without your vehicle until it's fixed. That's why we have a staff that excels at providing superior maintenance and repair and is able to do it quickly.",
          servisList: "OUR SERVICES INCLUDE",
        },
        // USLUGE
        usluge: {
          uslugeTitle: "OUR SERVICES",
          uslugeBio1: "Make your pet a sport version.",
          uslugeBio2:
            "Here's what else we offer to improve the aesthetics and quality of your vehicle. The experience of our employees and the equipment of our sales and service center guarantee excellent and high-quality service when buying and servicing your car.",
          uslugeBio3: "Duration and price vary, depending on the car model.",
          uslugeContact:
            "We will contact you to confirm your service appointment.",
        },
        // Sound modul
        soundModul: {
          title: "SOUND MODULE",
          description1:
            "Do you want to give your vehicle more feel, or is your stock exhaust system not producing the sound you want? Do you drive an electric or hybrid vehicle and perhaps have no driving noise at all? We know how frustrating that small detail can be, and that's why we have a simple solution.",
          description2:
            "Using the Panthera Connect smartphone apps, you can configure the Leo Active Sound System however you want. At the touch of a button, volume, sound, speed, assign a sound profile and activate effects.",
          servicesList: ["Sound", "Installation", "Bass"],
          moreBtn: "More",
        },
        // Tapeciranje
        tapeciranje: {
          title: "UPHOLSTERY",
          description1:
            "When it comes to upholstery, we use tradition and modern technology to raise the appearance and atmosphere inside the car to a completely higher level. In addition to classic, modern and sports design, we offer restoration and personalization, i.e. design according to your wishes.",
          description2:
            "The materials available to you are leather, sky, eco leather, alcantara, fabric, velor and others.",
          servicesList: [
            "Partial or complete seat upholstery",
            "Reconstruction of the seat structure",
            "Covering the steering wheel in leather or imitation leather",
            "Arm rest upholstery",
            "Reconstruction of the seat structure",
          ],
          btn: "Order",
        },
        // Wrap
        wrap: {
          title: "WRAP",
          description1:
            "Vehicle wrapping offers many benefits. Maybe you want protection from external factors or change the look of the vehicle and show your creativity. We have a wide range of colors, textures and finishes that make your car unique.",
          description2:
            "First of all, it is important that the entire process is done professionally. This gives you a long-lasting effect that protects your car for years to come.",
          servicesList: "",
          btn: "Order",
        },
        // Ugradnja felgi
        felge: {
          title: "RIMS INSTALLATION",
          description1:
            "We are experts in the supply and installation of alloy rims. Between all sizes, colors and patterns, you will find the perfect balance between style and performance with us.",
          description2:
            "A wide assortment is very important, because wheels are a very specific and technically complex part of the vehicle and safety is in the first place, that's why you can consult with us experts who have years of experience in the industry.",
          servicesList: "",
          btn: "Order",
        },
        // Pojasevi u boji
        pojasevi: {
          title: "COLORED BELTS",
          description1:
            "Any color breaks the monotony of the vehicle's interior, so it's hard to go in the wrong direction. We offer all colors and patterns, but only the highest quality materials.",
          description2:
            "With the service of upholstery and color stitching, the interior can give a completely new travel experience.",
          btn: "Order",
        },
        // Bojanja čeljusti
        celjusti: {
          title: "JAW COLORING",
          description1:
            "Any color breaks the monotony of the vehicle's interior, so it's hard to go in the wrong direction. We offer all colors and patterns, but only the highest quality materials.",
          description2:
            "With the service of upholstery and color stitching, the interior can give a completely new travel experience.",
          btn: "Order",
        },
        // Zatamnjivanje stakla
        stakla: {
          title: "GLASS TINTING",
          description1:
            "Whether you're looking for protection from light or simply a more subtle atmosphere in the back seat, it's important to have window tinting done by a skilled hand.",
          description2:
            "The protective film stops 99% of harmful radiation, protects passengers and the interior. In addition to preventing the shattering of pieces of glass in the event of an accident and protecting against injury, it also enhances the overall appearance of the vehicle.",
          servicesList: [
            "Front windshield - 0%",
            "Front side - 30%",
            "Rear windows - no limit",
          ],
          btn: "Order",
        },
        // PROJEKTI
        projekti: {
          title: "OUR PROJECTS",
          galleryTitle: "CAR GALLERY",
          projectTitle: "On this vehicle, we finished:",
          moreBtn: "More projects",
          brabusAMG: {
            list1: "Carbon package and sound",
            list2: "Rear diffuser sills",
            list3: "Matt black foil",
            list4: '21" Brabus aluminum rims',
          },
          fiatAbarath: {
            list1: "Colored belts",
            list2: "Jaw painting",
            list3: "Alcantara door linings",
            list4: "Akrapović exhaust system",
          },
          brabusG800: {
            list1: '23" forged alloy wheels',
            list2: "Chrome exhaust pipes",
            list3: "Interior finishing",
            list4: "Adding Brabus graphics",
          },
          mercedesA250: {
            list1: "Brabus diffuser",
            list2: "Exhaust system",
          },
          smartEQ: {
            list1: "Front spoiler",
            list2: "Side sills",
          },
          smart: {
            list1: "Wrap",
          },
        },
        // CONTACT
        contact: {
          contactTitle: "CONTACT",
          contactMonFri: "Monday-Friday",
        },
        // FORM
        form: {
          formTitle: "CONTACT US",
          formOrder: "ORDER:",
          formReservation: "BOOK AN APPOINTMENT:",
          formName: "Name and surname",
          formNamePH: "Name and surname",
          formEmailPH: "example@example.com",
          formPhone: "Phone",
          // Select
          // Select Servis
          formSelectServis: "Select a service:",
          formSelectServices: "Select a service:",
          formSelect: "Choose:",
          formSelectRepair: "General car repair",
          formSelectMaintenance: "Preventive maintenance of the car",
          formSelectAir: "Air conditioner and heater service",
          formSelectCooling: "Repair of the cooling system",
          formSelectEngineOil: "Engine oil replacement",
          formSelectOilFilter: "Oil filter replacement",
          formSelectBrakes: "Brake repair",
          formSelectDiagnostics: "Engine diagnostics",
          formSelectTransmission: "Transmission services",
          // Select Usluge
          formSelectSound: "Sound module",
          formSelectUpholstery: "Upholstery",
          formSelectWrap: "Wrap",
          formSelectRims: "Rims installation",
          formSelectBelts: "Colored belts",
          formSelectPainting: "Painting brake calipers",
          formSelectGlass: "Glass tinting",
          formSelectOther: "Other",
          // Car / Model
          formCarModel: "Vehicle/model ",
          formCarModePH: "Vehicle / model",
          // Date
          formDate: "Choose a date:",
          // Message
          formSend: "Send message",
          formSendPH: "Your message...",
          // Submit
          formSubmit: "SUBMIT",
          // Sent & ERROR
          formThankYouTitle: "MESSAGE SENT! ",
          formThankYouText: "We will contact you as soon as possible!",
          formErrorTitle: "AN ERROR HAS OCCURRED! ",
          formErrorText: "Please try again later or call us ",
        },
        // FOOTER
        footer: {
          footerCountry: "Croatia",
          footerContacts: "CONTACTS",
          footerInfoTitle: "COMPANY INFO",
          footerInfoCourt:
            "Court of entry in the register: Commercial Court in Zagreb",
          footerInfoCourt2: "opened in Privredna banka Zagreb d.d.",
          footerInfoCourt3: "Basic capital: HRK 20,000.00, paid in full",
          footerLinksTitle: "LINKS",
          footerTerms: "Terms of Use",
          footerPolicy: "Privacy Policy",
        },
        // TERMS & PRIVACY
        termsOfUse: {
          title: "TERM OF USE",
          termsBio1:
            "Users and customers are required, before using the website (www.autoconnect.hr) of the service provider Autoconnect, headquartered in Zagreb, Zagrebačka 33 - 10360 Sesvete, to familiarize themselves with the General Terms of Use of the website. If they have additional questions or uncertainties regarding the terms of use, they can contact the email address knjigovodstvo@autoconnect.hr.",
          termsBio2:
            "By submitting inquiries, signing contracts, or making direct contact, you agree that you voluntarily provide Autoconnect with your personal data and the data of the business entity you represent or represent. You allow them to be used to protect personal interests in all business dealings you undertake with Autoconnect.",
          termsBio3:
            "Autoconnect commits to protect the personal data of customers by collecting only necessary, basic customer/user data required to fulfill our business obligations.",
          termsBio4:
            "All user data is stored and only necessary data for conducting business is made available. All Autoconnect employees and business partners are responsible for adhering to privacy protection principles.",
          termsBio5:
            "The collection and processing of personal data for the purpose of executing agreed-upon transactions include the transmission of client personal data to third parties and business entities in the Republic of Croatia and abroad. Third parties include all individuals and business entities necessary for the realization of contracted transactions.",
          termsBio6:
            "Autoconnect's external links contain links to websites outside its own website. Autoconnect publishes links in good faith and cannot be held responsible for content outside of this website.",
          termsBio7:
            "It is the duty and obligation of users to use the website in accordance with positive regulations and general moral and ethical principles. Autoconnect has the right to control the content of the website at any time to ensure compliance with the General Terms and positive regulations. Changes to the terms of use are effective immediately upon publication on the website www.autoconnect.hr.",
        },
        privacyPolicy: {
          title: "PRIVACY POLICY",
          privacyBio1:
            "Autoconnect Ltd., OIB: 27909788832, Zagrebačka 33, Zagreb-Sesvete, collects and processes personal data of its service users in its business operations and takes measures to ensure that the processing of personal data is lawful, fair, and transparent. The collection, processing, and use of data are carried out in accordance with data protection regulations, and the terms used in this Statement have the meaning defined by EU Regulation 2016/679.",
          privacyBio2:
            "You can browse and use this website anonymously, as no data is collected that would enable your identification as an individual, and you are not obligated to provide us with your personal data as a condition for using the site.",
          privacyBio3:
            "By entering personal data into the designated fields on the website, you confirm that you have voluntarily made your personal data available and permit its use for the purpose for which the data is provided.",
          privacyBio4:
            "We process and use your data for the purpose of fulfilling contracts in which you are a party and to take actions at your request before entering into a contract (e.g., reservation and rental of vehicles, etc.) and if processing is necessary to comply with Autoconnect's legal and statutory obligations (e.g., accounting operations, providing your data to the relevant authority if the vehicle you used was involved in an offense, etc.).",
          privacyBio5:
            "The data collected from you and about you remains in Autoconnect's databases. Your data may be disclosed to third parties when required by law or in response to legal proceedings or requests from law enforcement agencies related to offenses, criminal or judicial proceedings, and for seeking appropriate legal remedies and limiting the damage that may be caused to us.",
          privacyBio6:
            "Data for which there is no storage period prescribed by laws or other regulations are stored for a reasonable period, taking into account the category of data and the purpose for which they were collected. Data collected for a specific purpose will be used only for that purpose, and after the expiration of a reasonable period and once that purpose is fulfilled, they will no longer be actively stored.",
        },
        // SOUND MODUL
        soundModulPage: {
          title1: "SOUND MODULE",
          soundText1:
            " Do you want to give your vehicle a more thrilling sound, or does your standard exhaust system not produce the sound you desire??",
          soundText2:
            "Are you driving an electric or hybrid vehicle and perhaps experiencing a lack of noise during your drives?",
          soundText3: "Our distributor Panthera has a solution for you.",
          soundH5: "Download the Panthera app:",
          appStore: "on App Store",
          googlePlay: "on Google Play",
          soundText4:
            "With the Panthera Connect smartphone app, you can configure the Leo Active Sound System according to your preferences.",
          // Instalacija
          title2: "INSTALLATION",
          soundText5:
            "Thanks to our OBD solution, installation is quick and straightforward. The active sound system speaker can be installed inside or outside the vehicle, as desired. Up to four speakers can be installed if needed.",
          soundText6:
            "Power is supplied through ignition plus and permanent plus via the provided adapter (plug and play) on the fuse box in the vehicle.",
          soundText7:
            "Then, the OBD-II adapter is plugged into the vehicle. The rest of the system operates wirelessly. ",
          btn: "Make a reservation",
          // Konfiguracija
          title3: "CONFIGURATION",
          soundText8:
            "The Leo Active Sound System comes pre-configured with standard settings, making it versatile for general use.",
          soundText9:
            "For a personalized sound experience, we recommend configuring it using the Panthera Connect 4.0 app.",
          soundText10:
            "Here, you can customize 8 different sound profiles and choose sound files.",
          soundText11:
            "Volume, speed, idle speed, ignition breaks – everything can be easily and clearly adjusted using the Panthera Connect 6.0 app.",
          soundText12:
            "Additionally, stay informed and regularly update. Updates can be easily installed through the apps.",
        },
        // ZASTUPSTVA
        zastupstva: {
          title: "REPRESENTATIONS",
          brabus: {
            brabusText1:
              "For over 40 years, Brabus has been developing and manufacturing high-performance supercars, transforming vehicles of the Mercedes-Benz brand into modern, unique cars made in Germany.",
            brabusText2:
              "Over four decades, BRABUS has, in a sense, mirrored the performance of each BRABUS engine: there is a strong push forward, and that push goes further and further. More and more displacement, more horsepower, more torque. More and more innovation.",
            brabusText3:
              "More and more internal meetings, more safety, more environmental friendliness. More and more exclusivity. And more records.",
            brabusText4:
              "Passion for cars, dedication to performance, a desire for perfection, a pursuit of superlatives, and a sense of aesthetics and functionality turn regular cars into BRABUS cars.",
            brabusText5:
              "The art of creating cars, both big and small, reflects top-notch technology and safety - time after time. Simply top-notch.",
            brabusH5: "ONE BRAND - ONE TEAM",
            brabusText6:
              "High-performance cars, top-notch tuning, and a lifestyle for the Mercedes-Benz and smart domain are BRABUS's expertise. Established in 1977, BRABUS GmbH is the world's largest independent specialist for car tuning and the manufacturer of exclusive supercars. ",
            brabusText7:
              "The globally renowned tuner and car manufacturer offer customized solutions for any demand: the BRABUS product range includes upgraded engines, elegantly styled aerodynamic enhancement kits, high-quality aluminum wheels, sporty yet comfortable sports suspensions, and exclusive interiors of the highest level. The company's headquarters are located in Bottrop, where spacious showrooms, marketing and sales, administration, as well as engineering and production departments, can be found.",
          },
          hamann: {
            hamannText1:
              "HAMANN Motorsport offers more than just vehicle tuning: since 1986, the name has stood for the development and customization of vehicles at the highest level.",
            hamannText2:
              "Fulfill your automotive dreams and rely on the expertise and experience of HAMANN Motorsport in design, quality, performance, and lifestyle.",
            hamannText3:
              "The best vehicle tuning - surrender to inspiration. Get inspired by our exclusive programs for top models of brands such as BMW, Range Rover, Lamborghini, Mercedes-Benz, and Porsche, especially Macan and Cayenne. Vehicle tuning at the highest level!",
            hamannH5: "EXCLUSIVE HAMANN TUNING FOR VERY SPECIAL CARS",
            hamannText4:
              "If you are looking for something special, individual, and unique, you will certainly find it at HAMANN Motorsport. The tuning program includes powerful wide-body concepts and sporty aerodynamic kits with many real carbon elements, performance-oriented chassis optimizations, high-quality lightweight aluminum wheels, performance enhancements, and much more.",
            hamannText5:
              "Hamann Tuning also offers something special with components for Panamera, Cayenne, or 911 (997). Hamann's developments for Mercedes-Benz G models G55, G63, and G65, as well as SLS AMG R197 and SLS AMG C197, are also noteworthy. The Lamborghini brand, embraced by Hamann, is marked by the concept of individualization and high-quality components for Aventador, Gallardo, LP 640, or Murciélago. HAMANN Motorsport also makes the automotive luxury class unmistakable - whether it's Rolls Royce Wraith, Drophead Coupe, Phantom, or Ghost, or Bentley Continental GT. Customers worldwide trust in the competence of HAMANN Motorsport - customization possibilities are limitless, and Hamann's team makes the impossible possible.",
          },
          techart: {
            techartText1:
              "Welcome to the world of premium customizations for your Porsche model. Top-notch customization for your Porsche model. Since 1987.",
            techartText2:
              " TECHART is an international premium brand for individual customization of every Porsche model. Driven by a special demand for excellent design and the highest level of development expertise. And last but not least, by handling emotions and reason responsibly. For lasting enjoyment of more individuality.",
            techartText3:
              "Our fundamental principle: OEM quality made in Germany – visible and invisible. Every genuine TECHART product is a result of this maxim.",
            // List 1
            techartList1:
              "Own design development and model construction at the Leonberg location",
            techartList2:
              "Aerodynamic test drives and optimization in the wind tunnel",
            techartList3: "CAD-supported development process",
            techartList4:
              "Precise tooling for serial production in polyurethane-RIM and carbon",
            techartList5:
              "Type approvals, part certificates, and homologations",
            techartList6:
              "Verification and validation in crash tests and material stress tests",
            techartList7: "Installation and servicing without complications",
            techartList8:
              "Component and driving tests for maximum performance and suitability for daily use",
            techartList9:
              "Quality materials such as PU-RIM, carbon fiber, hand-picked leather, and precious wood",
            techartH5: "TECHART is freedom",
            techartText4:
              "Everyone has their view of things. Their own skills and talents. Their own way of thinking and living. Your own perception of freedom and individuality. – So, if you decide to transfer a part of your personality to your Porsche model, what would true individuality mean to you? What would be your measure of uniqueness?",
            techartText5:
              "Enjoy the freedom of emphasizing those characteristic traits in your vehicle that signify individuality and uniqueness for you. With TECHART, you have an almost unlimited range of possibilities.",
            // List 2
            techartList10:
              "Aerodynamic and styling packages tested in the wind tunnel",
            techartList11:
              "Surfaces and parts made of carbon fiber for the body and interior",
            techartList12:
              "Powerful engine enhancements and driving dynamics systems",
            techartList13: "Breath-taking sound exhaust systems",
            techartList14:
              "Light alloy wheels with a 5-hole connection and central lock",
            techartList15: "Interior refinements and handmade steering wheels",
            techartText6:
              "TECHART's aerodynamic and styling options highlight the sporty orientation of personalized Porsche vehicles from every angle. Typically TECHART: unmistakably distinguishing from the series. Yet, reserved enough to retain Porsche's design line.",
            techartText7:
              " The selection of TECHART exterior options is diverse. It includes entire front and rear parts that seamlessly integrate into the body shape. Front spoiler and rear wing that make a sporty statement at first glance.",
            techartText8:
              "Accompanied by an almost unlimited choice of TECHART body components, such as side skirts, engine lids, roof spoilers, as well as exterior panel parts and surface finishes for serial components.",
          },
        },
      },
    },
  },
  lng: "hr",
  fallbackLng: "hr",
  interpolation: {
    escapeValue: false,
  },
});

export default function Language() {
  const { t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language">
      <button onClick={() => changeLanguage("hr")}>
        <img src={CroatiaFlag} alt={t("Croatian Flag")} />
      </button>
      <button onClick={() => changeLanguage("en")}>
        <img src={UKFlag} alt={t("UK Flag")} />
      </button>
    </div>
  );
}
