// Images
import SoundBackground from "../../../Images/ostalo/Sound-modul-bg.jpg";
import Panthera1 from "../../../Images/usluge/Panthera.JPG";
import Panthera2 from "../../../Images/ostalo/Panthera-sound-modul1.JPG";
import PantheraVideo1Poster from "../../../Images/ostalo/Panthera-Video-Poster.jpg";
// Videos
import PantheraVideo1 from "../../../Images/videos/Panthera-Leo-Active-Sound-New-C-class-W206.mp4";
import SoundModulVideo1 from "../../../Images/videos/Sound-modul-video.mp4";
// Language
import { useTranslation } from "react-i18next";

// React
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Main from "../Main/Main";
import { useState } from "react";

export default function SoundModul() {
  return (
    <>
      <Main imageUrl={SoundBackground} altText="Background Sound modul" />
      <SoundModulSection />
      <div className="border"></div>
      <SoundModulInstalacija />
      <div className="border"></div>
      <SoundModulKonfiguracija />
    </>
  );
}

function SoundModulSection() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const { t } = useTranslation();

  return (
    <>
      <section className="section" id="sound-modul">
        <h2>{t("soundModulPage.title1")}</h2>
        <p>{t("soundModulPage.soundText1")}</p>
        <p>{t("soundModulPage.soundText2")}</p>
        <p>{t("soundModulPage.soundText3")}</p>
        <div className="sound-panthera-box">
          <h5>{t("soundModulPage.soundH5")}</h5>
          <div>
            <Link
              className="panthera-app-btn"
              to="https://apps.apple.com/us/app/panthera-connect-6-0/id1469910456"
            >
              <i className="fi fi-brands-apple"></i>
              {t("soundModulPage.appStore")}
            </Link>
            <Link
              className="panthera-app-btn"
              to="https://play.google.com/store/apps/details?id=de.panthera.connect&pcampaignid=web_share"
            >
              <i className="fi fi-brands-android"></i>
              {t("soundModulPage.googlePlay")}
            </Link>
          </div>
        </div>
        <div className="sound-grid">
          <img
            className="sound-images"
            src={Panthera1}
            alt="Sound modul"
            onClick={() => openImage(Panthera1)}
          />
          <img
            className="sound-images"
            src={Panthera2}
            alt="Sound modul"
            onClick={() => openImage(Panthera2)}
          />
        </div>
        <p>{t("soundModulPage.soundText4")}</p>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}

function SoundModulInstalacija() {
  const { t } = useTranslation();
  return (
    <>
      <section className="section">
        <h2>{t("soundModulPage.title2")}</h2>
        <p>{t("soundModulPage.soundText5")}</p>
        <p>{t("soundModulPage.soundText6")}</p>
        <p>{t("soundModulPage.soundText7")}</p>
        <div className="sound-grid">
          <video
            className="sound-modul-video"
            controls
            disablePictureInPicture
            controlsList="nodownload"
            poster={PantheraVideo1Poster}
          >
            <source src={PantheraVideo1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <video
            className="sound-modul-video"
            controls
            disablePictureInPicture
            controlsList="nodownload"
          >
            <source src={SoundModulVideo1} />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="usluge-btn">
          <HashLink smooth to="/usluge/#section-form">
            {t("soundModulPage.btn")} &rarr;
          </HashLink>
        </div>
      </section>
    </>
  );
}

function SoundModulKonfiguracija() {
  const { t } = useTranslation();
  return (
    <>
      <section className="section">
        <h2> {t("soundModulPage.title3")}</h2>
        <p>{t("soundModulPage.soundText8")}</p>
        <p>{t("soundModulPage.soundText9")}</p>
        <p>{t("soundModulPage.soundText10")}</p>
        <p>{t("soundModulPage.soundTex11")}</p>
        <p>{t("soundModulPage.soundText12")}</p>
      </section>
    </>
  );
}
