import { useEffect, useState } from "react";
import ServisBackground from "../../../Images/ostalo/service.jpg";
import ServisniCentar from "./ServisniCentar";
import Main from "../Main/Main";
// Language
import { useTranslation } from "react-i18next";

export default function Servis() {
  useEffect(() => {
    document.title = "Autoconnect | Servis";
  }, []);
  return (
    <>
      <Main imageUrl={ServisBackground} altText="Servis" />
      <ServisniCentar />
      <ServisForm />
    </>
  );
}

function ServisForm() {
  const [isMessageSent, setMessageSent] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Your existing form data
    const formData = new FormData(event.target);

    try {
      // Submit the form data to the web3forms API
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      // Check if the form submission was successful
      if (response.ok) {
        setMessageSent(true);
      } else {
        const errorMessage = await response.text();
        setError(errorMessage);
      }
    } catch (error) {
      // Handle network or other errors
      setError("An error occurred. Please try again later.");
    }
  };
  useEffect(() => {
    const form = document.getElementById("form");
    const dateInput = document.getElementById("date");

    const handleFormSubmit = (e) => {
      const hCaptcha = form.querySelector(
        "textarea[name=h-captcha-response]"
      ).value;

      if (!hCaptcha) {
        e.preventDefault();
        alert("Please fill out the captcha field");
        return;
      }
    };

    const handleDateInputChange = () => {
      const selectedDate = new Date(dateInput.value);
      const selectedDay = selectedDate.getDay();
      const errorMessageSpan = document.getElementById("error-message");

      if (selectedDay === 0 || selectedDay === 6) {
        errorMessageSpan.textContent =
          "Odaberite samo radne dane u tjednu (Pon-Pet)";
        dateInput.value = formatDate(new Date());
      } else {
        errorMessageSpan.textContent = "";
      }
    };

    form.addEventListener("submit", handleFormSubmit);

    dateInput.min = formatDate(new Date());
    dateInput.value = formatDate(new Date());
    dateInput.addEventListener("input", handleDateInputChange);

    // Cleanup event listeners on component unmount
    return () => {
      form.removeEventListener("submit", handleFormSubmit);
      dateInput.removeEventListener("input", handleDateInputChange);
    };
  }, []);

  // Function to format the date as 'YYYY-MM-DD' in form
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <section id="section-form" className="section">
        <h3>{t("form.formOrder")}</h3>
        {!isMessageSent ? (
          <form onSubmit={handleSubmit} className="contact-form" id="form">
            {/*  <!-- Key --> */}
            <input
              type="hidden"
              name="access_key"
              value="ad815d2a-19f1-4774-9798-5d3dd2b1be90"
            />

            {/*  <!-- From name --> */}
            <input type="hidden" name="from_name" value="Servis AutoConnect" />
            {/*  <!-- Subject name --> */}
            <input type="hidden" name="subject" value="Nova rezervacija" />
            {/* <!--  --> */}
            <label htmlFor="name">{t("form.formName")}:</label>
            <input
              type="text"
              name="Ime i Prezime"
              placeholder={t("form.formNamePH")}
              id="name"
              autoComplete="name"
            />
            <label htmlFor="email">
              Email: <span>*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              placeholder={t("form.formEmailPH")}
              required
            />
            <label htmlFor="phone">{t("form.formPhone")}:</label>
            <input
              type="tel"
              name="Broj mobitela"
              id="phone"
              placeholder="+385 1 234 5678"
              autoComplete="phone"
            />

            <label htmlFor="option">
              {t("form.formSelectServis")}
              <span>*</span>
            </label>
            <select name="Servis" id="option" required>
              <option defaultValue="Odaberite" disabled selected>
                {t("form.formSelect")}
              </option>
              <option defaultValue="Generalni&nbsp;popravak&nbsp;automobila">
                {t("form.formSelectRepair")}
              </option>
              <option defaultValue="Preventivno&nbsp;održavanje&nbsp;automobila">
                {t("form.formSelectMaintenance")}
              </option>
              <option defaultValue="Servis&nbsp;klima&nbsp;uređaja&nbsp;i&nbsp;grijača">
                {t("form.formSelectAir")}
              </option>
              <option defaultValue="Popravak&nbsp;rashladnog&nbsp;sustava&nbsp;i&nbsp;hladnjaka">
                {t("form.formSelectCooling")}
              </option>
              <option defaultValue="Zamjena&nbsp;motornog&nbsp;ulja">
                {t("form.formSelectEngineOil")}
              </option>
              <option defaultValue="Zamjena&nbsp;filtera&nbsp;za&nbsp; ulje">
                {t("form.formSelectOilFilter")}
              </option>
              <option defaultValue="Popravak&nbsp;kočnica">
                {t("form.formSelectBrakes")}
              </option>
              <option defaultValue="Dijagnostika&nbsp;motora">
                {t("form.formSelectDiagnostics")}
              </option>
              <option defaultValue="Usluge&nbsp;prijenosa">
                {t("form.formSelectTransmission")}
              </option>
            </select>
            <label htmlFor="car">
              {t("form.formCarModel")} <span>*</span>
            </label>
            <input
              type="text"
              name="Vozilo/model"
              id="car"
              placeholder={t("form.formCarModePH")}
              required
            />
            <label htmlFor="date">{t("form.formDate")}</label>
            <input type="date" id="date" name="Datum" />
            <span id="error-message"></span>
            <label htmlFor="message">{t("form.formSend")}</label>
            <textarea
              name="Poruka"
              id="message"
              placeholder={t("form.formSendPH")}
            ></textarea>
            <input type="submit" value={t("form.formSubmit")} id="submit-btn" />
          </form>
        ) : (
          <div className="thank-you">
            <h1>
              {t("form.formThankYouTitle")}
              <i className="fi fi-ss-check-circle"></i>
            </h1>
            <p>{t("form.formThankYouText")}</p>
          </div>
        )}
        {error && (
          <div className="error-box">
            <h1>
              {t("form.formErrorTitle")}
              <i class="fi fi-ss-exclamation"></i>
            </h1>
            <p>{t("form.formErrorText")}</p>
            <p>
              <a href="tel:+385 1 2911 500">
                <p>
                  <i className="fi fi-ss-phone-call"></i> +385 1 2911 500
                </p>
              </a>
            </p>
          </div>
        )}
      </section>
    </>
  );
}
