import { useEffect, useState } from "react";
import Main from "../Main/Main";
import { HashLink } from "react-router-hash-link";
import { Link, useNavigate } from "react-router-dom";
// Language
import { useTranslation } from "react-i18next";

// Images
import Techart1 from "../../../Images/zastupstva/techart/Techart.jpg";
import Techart2 from "../../../Images/zastupstva/techart/Techart2.jpg";
import Techart3 from "../../../Images/zastupstva/techart/Techart3.jpg";
import Techart4 from "../../../Images/zastupstva/techart/Techart4.jpg";
import Techart5 from "../../../Images/zastupstva/techart/Techart5.png";

export default function Hamann() {
  useEffect(() => {
    document.title = "Autoconnect | Techart";
  }, []);
  return (
    <>
      <Main imageUrl={Techart1} altText="Brabus" />
      <TechartText />
    </>
  );
}

function TechartText() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  const { t } = useTranslation();
  return (
    <>
      <section className="section">
        <h2>TECHART</h2>
        <div className="zastupstva-text">
          <p>{t("zastupstva.techart.techartText1")}</p>
          <div className="zastupstva-grid">
            <div>
              <img
                src={Techart2}
                alt="Techart"
                onClick={() => openImage(Techart2)}
              />
              <img
                src={Techart3}
                alt="Techart"
                onClick={() => openImage(Techart3)}
              />
            </div>
          </div>
          <p>{t("zastupstva.techart.techartText2")}</p>

          <p>{t("zastupstva.techart.techartText3")}</p>
          <div className="border"></div>
          <ul className="zastupstva-links">
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList1")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList2")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList3")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList4")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList5")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList6")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList7")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList8")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList9")}
            </li>
          </ul>
        </div>

        <div className="zastupstva-text">
          <h5>{t("zastupstva.techart.techartH5")}</h5>
          <p>{t("zastupstva.techart.techartText4")}</p>
          <div className="zastupstva-grid">
            <img
              src={Techart4}
              alt="Techart"
              onClick={() => openImage(Techart4)}
            />

            <p>{t("zastupstva.techart.techartText5")}</p>
          </div>
          <div className="border"></div>
          <ul className="zastupstva-links">
            <li>
              <span>&#x2022;</span>
              {t("zastupstva.techart.techartList10")}
            </li>
            <li>
              <span>&#x2022;</span>
              {t("zastupstva.techart.techartList11")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList12")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList13")}
            </li>
            <li>
              <span>&#x2022;</span> K{t("zastupstva.techart.techartList14")}
            </li>
            <li>
              <span>&#x2022;</span> {t("zastupstva.techart.techartList15")}
            </li>
          </ul>
          <p>{t("zastupstva.techart.techartText6")}</p>

          <div className="zastupstva-grid">
            <img
              src={Techart5}
              alt="TechArt"
              onClick={() => openImage(Techart5)}
            />
            <p>{t("zastupstva.techart.techartText7")}</p>
          </div>

          <p>{t("zastupstva.techart.techartText8")}</p>
          <Link to="https://www.techart.de/">https://www.techart.de/</Link>

          <div className="zastupstva-img-box"></div>
        </div>
        <div className="zastupstva-buttons">
          <HashLink
            smooth
            to="/zastupstva/brabus"
            className="btn"
            onClick={() => handleClick("/zastupstva/brabus")}
          >
            BRABUS &rarr;
          </HashLink>
          <HashLink
            smooth
            to="/zastupstva/hamann"
            className="btn"
            onClick={() => handleClick("/zastupstva/hamann")}
          >
            HAMANN &rarr;
          </HashLink>
        </div>
      </section>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
