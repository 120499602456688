import ServisniCentar1 from "../../../Images/ostalo/Servis motora.jpg";
import ServisniCentar2 from "../../../Images/ostalo/Servis-car.jpg";
import { useState } from "react";
// Language
import { useTranslation } from "react-i18next";

export default function ServisniCentar() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const { t } = useTranslation();
  return (
    <>
      <section className="section servisni-centar">
        <h1>{t("servis.servisTitle")}</h1>
        <p>{t("servis.servisBio")}</p>

        <div>
          <h3 style={{ marginTop: "30px" }}>{t("servis.servisList")}:</h3>
          <div className="servis-grid">
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-settings"></i>
              <h5>{t("form.formSelectRepair")}</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-car-alt"></i>
              <h5>{t("form.formSelectMaintenance")}</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-thermometer-half"></i>
              <h5>{t("form.formSelectAir")}</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-snowflake"></i>
              <h5>{t("form.formSelectCooling")}</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-oil-temp"></i>
              <h5>{t("form.formSelectEngineOil")}</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-oil-can"></i>
              <h5>{t("form.formSelectOilFilter")}</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-tire"></i>
              <h5>{t("form.formSelectBrakes")}</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-engine-warning"></i>
              <h5>{t("form.formSelectDiagnostics")}</h5>
            </div>
            {/* <!--  --> */}
            <div className="servis-box">
              <i className="fi fi-ss-data-transfer"></i>
              <h5>{t("form.formSelectTransmission")}</h5>
            </div>
          </div>
        </div>
        <div className="servis-img">
          <img
            src={ServisniCentar1}
            alt="Serivsni centar"
            onClick={() => openImage(ServisniCentar1)}
          />
          <img
            src={ServisniCentar2}
            alt="Serivsni centar"
            onClick={() => openImage(ServisniCentar2)}
          />
        </div>
        {/* *****SHOW IMAGES ON CLICK***** */}
        {showImage && (
          <div id="overlay" onClick={closeImage}>
            <div id="popup">
              <span id="close" onClick={closeImage}>
                &times;
              </span>
              <img
                id="popupImage"
                src={showImage}
                style={{ width: "100%", height: "100%" }}
                alt="Show"
              />
            </div>
          </div>
        )}

        {/* <!--  --> */}
      </section>
    </>
  );
}
